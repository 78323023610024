import React from 'react'

function Exclamation() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="relative inline mr-3 bottom-0.5 text-sm rotate-180"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5545 10.7061C19.5545 15.3356 15.6027 19.2061 10.5796 19.2061C5.55656 19.2061 1.60471 15.3356 1.60471 10.7061C1.60471 6.07646 5.55656 2.20605 10.5796 2.20605C15.6027 2.20605 19.5545 6.07646 19.5545 10.7061ZM21.0545 10.7061C21.0545 16.2289 16.3647 20.7061 10.5796 20.7061C4.79448 20.7061 0.104706 16.2289 0.104706 10.7061C0.104706 5.18321 4.79448 0.706055 10.5796 0.706055C16.3647 0.706055 21.0545 5.18321 21.0545 10.7061ZM11.3296 4.70605V12.7061H9.82962V4.70605H11.3296ZM11.6271 15.7061C11.6271 16.2583 11.1581 16.7061 10.5796 16.7061C10.0011 16.7061 9.53213 16.2583 9.53213 15.7061C9.53213 15.1538 10.0011 14.7061 10.5796 14.7061C11.1581 14.7061 11.6271 15.1538 11.6271 15.7061Z"
        fill="#FF5CAA"
      />
    </svg>
  )
}

export default Exclamation
